/* You can add global styles to this file, and also import other style files */
@import '~src/shared/variables';
@import '~src/shared/forms';

html, body { height: 100%; }
body {
  background-color: $base-bgcolor;
  color: $base-color;
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family, $base-font-family-fallback;
  margin: 0;
  min-height: 100%;

  margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;
}

.directive-error {
  border: 1px solid hsl(0 100% 50%);
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 10px;
  color: hsl(0deg, 100%, 60%);
}
